.taxCalculator {
    border: 4px solid black;
}

/* Chrome, Safari, Edge, Opera */
.desiredNetIncome::-webkit-outer-spin-button,
.desiredNetIncome::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.desiredNetIncome[type=number] {
    -moz-appearance: textfield;
}

.submitButton {
    margin-left: auto;
    display: block;
}

.inputContainer {
    border-bottom: 2px solid black;
}

.resultContainer {
    border-top: 2px solid black;
}

.resultCell {
    font-weight: bold !important;
    color: black !important;
    font-size: 16px !important;
    line-height: 1.5;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.ExplanationContainer:before {
    background-color: white !important;
}

.ExplanationContainer {
    box-shadow: none !important;
    background-color: white !important;
    margin: 0 !important;
}

